import React from 'react';
import Seo from 'components/Seo/Seo';
import Nav from 'components/Nav/Nav';
import Projects from 'components/Projects/Projects';
import Footer from 'components/Footer/Footer';

export default function ProjectsPage() {
  return (
    <React.Fragment>
      <Seo title="UX Projects" />
      <Nav />
      <Projects />
      <Footer />
    </React.Fragment>
  );
}
